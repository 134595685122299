import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Config from '../../config';

class Tweet extends Component {
  render() {
    const statusId = this.props.statusUrl.split('/').pop();
    return (
      <div style={{ maxWidth: '280px', paddingLeft: '10px', marginTop: '10px' }}>
        <a href={this.props.statusUrl} target="_blank">
          <img src={`${Config.djangoHost}/static/landing/img/tweets/${statusId}.png`} width="100%" />
        </a>
      </div>
    );
  }
}

class TwitterTestimonials extends Component {
  render() {
    if (this.props.isAbroad) {
      return null;
    }

    const styleRow = {
      display: 'inline-flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      verticalAlign: 'middle',
      float: 'none',
      alignItems: 'center',
      marginTop: '50px',
    };

    return (
      <div
        id="persuasion-complete"
        style={{
          marginBottom: '100px',
        }}
      >
        <div className="row" style={styleRow}>
          <Tweet statusUrl="https://twitter.com/BBMUSP/status/636977613383315456" />
          <Tweet statusUrl="https://twitter.com/UFSMFacts/status/639034922557116416" />
          <Tweet statusUrl="https://twitter.com/bcrpusp/status/636995573967659008" />
          <Tweet statusUrl={'https://twitter.com/porto_digital/status/638043339946897408'} />
          <Tweet statusUrl={'https://twitter.com/TechTudo/status/1454059207527833606'} />
          <Tweet statusUrl={'https://twitter.com/w_rabelo/status/844567820411265026'} />
          <Tweet statusUrl="https://twitter.com/srlm/status/640298516401815552" />
          <Tweet statusUrl={'https://twitter.com/BibSombrio/status/666606291742236672'} />
          <Tweet statusUrl={'https://twitter.com/CoxThamy/status/1499377228672548865'} />
          <Tweet statusUrl={'https://twitter.com/NaoFrozen/status/832172437945143297'} />
          <Tweet statusUrl={'https://twitter.com/_LadyUnicorn_/status/861084166284333057'} />
          <Tweet statusUrl={'https://twitter.com/luznmicK/status/1696253679764959624'} />
          <Tweet statusUrl={'https://twitter.com/plantaleo/status/1673564442859122688'} />
          <Tweet statusUrl={'https://twitter.com/matheusedutt/status/1610700564505202688'} />
          <Tweet statusUrl={'https://twitter.com/_adioslari/status/855437018851143680'} />
          <Tweet statusUrl="https://twitter.com/gabiboninii/status/1493688282575683586" />
        </div>
      </div>
    );
  }
}

TwitterTestimonials.propTypes = {
  isAbroad: PropTypes.bool,
};

export default TwitterTestimonials;
