import React, { Component } from 'react';
import Config from '../../config';
import Ajax from '../../common/ajax';
import LazyImage from '../../common/components/LazyImage';
import Waiting from '../../common/containers/Waiting';

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonials: [],
    };
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
    this.fetchTestimonials();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  fetchTestimonials() {
    const url = `${Config.apiHost}checkouts/testimonials/`;
    Ajax.get(url).done((testimonials) => {
      if (this._mounted) {
        this.setState((state) => ({ ...state, testimonials }));
      }
    });
  }

  render() {
    return (
      <Waiting isProcessing={!this.state.testimonials || !this.state.testimonials.length}>
        <div
          className="carousel slide quote-carousel"
          data-ride="carousel"
          data-interval="false"
          id="quote-carousel-fastformat"
        >
          <ol className="carousel-indicators">
            {this.state.testimonials.map((t, idx) => {
              return (
                <li
                  data-target="#quote-carousel-fastformat"
                  data-slide-to={idx}
                  className={`${t.active ? 'active' : ''}`}
                  key={idx}
                >
                  <LazyImage
                    src={`${Config.djangoHost}static/sales/images/${t.image}`}
                    alt={t.name}
                    title={t.name}
                    className="img-responsive"
                  />
                </li>
              );
            })}
          </ol>
          <div className="carousel-inner text-center">
            {this.state.testimonials.map((t, idx) => {
              return (
                <div className={`item ${t.active ? 'active' : ''}`} key={idx}>
                  <blockquote>
                    <div className="row">
                      <div className="col-sm-10 col-sm-offset-1">
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <i className="fas fa-star text-warning" />
                        <p>{t.text}</p>
                        <small>
                          {t.name}
                          <br />
                          <span dangerouslySetInnerHTML={{ __html: t.work_title }} />
                          <br />
                          {t.institution}
                        </small>
                      </div>
                    </div>
                  </blockquote>
                </div>
              );
            })}
            <a data-slide="prev" href="#quote-carousel-fastformat" className="left carousel-control">
              <i className="fas fa-chevron-left" />
            </a>
            <a data-slide="next" href="#quote-carousel-fastformat" className="right carousel-control">
              <i className="fas fa-chevron-right" />
            </a>
          </div>
        </div>
      </Waiting>
    );
  }
}

export default Testimonials;
